import React, { useState } from "react";

import "../pages.scss";

import ProfilePublishersTable from "../Components/ProfilePublishersTable";
import ProfilesAppsTable from "../Components/ProfilesAppsTable";

const ProfileApplicationsControl = ({
  selectedProfile,
  showCount,
  setShowCount,
  tenantName,
}) => {
  const [selectedPublisher, setSelectedPublisher] = useState(undefined);
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const handleOpen = () => {
    setIsBoxVisible(true);
  };
  const handleClose = () => {
    setIsBoxVisible(false);
  };

  return (
    <section className="applicationTab">
      <ProfilePublishersTable
        selectedProfile={selectedProfile}
        selectedPublisher={selectedPublisher}
        setSelectedPublisher={setSelectedPublisher}
        handleClose={handleClose}
      />
      {selectedPublisher ? (
        <ProfilesAppsTable
          selectedPublisher={selectedPublisher}
          selectedProfile={selectedProfile}
          showCount={showCount}
          setShowCount={setShowCount}
          tenantName={tenantName}
          handleClose={handleClose}
          handleOpen={handleOpen}
          isBoxVisible={isBoxVisible}
        />
      ) : null}
    </section>
  );
};

export default ProfileApplicationsControl;
