import React, { useState, useEffect } from "react";

import {
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import StyledTooltip from "../../../components/StyledTooltip";
import useUserStore from "../../../../services/userStore";
import { useQuery } from "react-query";
import { getPublishersById } from "../../api";
import { myLocalStorage } from "../../../../components/StorageHelper";

const EndpointsPublishersTable = ({
  selectedPublisher,
  setSelectedPublisher,
  tenantName,
  allAppsPublisher,
  handleClose,
}) => {
  const selectedTenantName =
    tenantName || myLocalStorage.getItem("latestTenant")?.tenantName;
  const activeComputerId =
    useUserStore((state) => state.activeComputer?.id) ||
    myLocalStorage.getItem("activeComputer")?.id;

  const [publisherSearchValues, setPublisherSearchValues] = useState("");
  const [unsignedAppsPublisher, setUnsignedAppsPublisher] = useState(null);
  const [hideEmptyPublishers, setHideEmptyPublishers] = useState(true);

  const filterPublishers = (publishers) => {
    if (publishers?.length) {
      const PublishersWithoutDuplicates = publishers
        .map((publisher) => ({ ...publisher, ids: [publisher.id] }))
        .reduce((acc, current) => {
          const x = acc.find(
            (publisher) => publisher.friendlyName === current.friendlyName,
          );
          if (!x) {
            return acc.concat([current]);
          } else {
            x.ids.push(current.id);
            return acc;
          }
        }, []);
      return hideEmptyPublishers
        ? PublishersWithoutDuplicates.filter((publisher) =>
            publisher.containsApps ? publisher : null,
          )
        : PublishersWithoutDuplicates;
    }
  };

  const findPublisher = (e) => {
    setTimeout(() => {
      setPublisherSearchValues(e.target.value);
    }, 1500);
  };

  const { data: publishers, isLoading } = useQuery({
    queryKey: ["publishers", selectedTenantName, activeComputerId],
    queryFn: () => getPublishersById(selectedTenantName, activeComputerId),
    enabled: !!selectedTenantName && !!activeComputerId,
  });

  const filteredPublishers =
    filterPublishers(publishers)
      ?.filter(
        (publisher) =>
          publisherSearchValues === "" ||
          publisher.friendlyName
            .toLowerCase()
            .includes(publisherSearchValues.toLowerCase()),
      )
      .filter((publisher) => publisher.friendlyName !== "Unsigned Apps") || [];

  useEffect(() => {
    if (publishers?.length && !selectedPublisher) {
      setSelectedPublisher(publishers[0]);
    } else setSelectedPublisher(allAppsPublisher);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishers]);

  useEffect(() => {
    const unsignedAppsPublisher = publishers?.find(
      (el) => el.friendlyName === "Unsigned Apps",
    );
    setUnsignedAppsPublisher(unsignedAppsPublisher);
  }, [publishers]);

  if (isLoading)
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );

  return (
    <>
      {publishers?.length ? (
        <TableContainer
          style={{ height: "fit-content", minWidth: 275 }}
          component={Paper}
        >
          <Table
            sx={{
              height: "fit-content",
              wordWrap: "break-word",
              maxWidth: "100%",
              "& td, & th": {
                border: "1px solid #233044",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ color: "white", backgroundColor: "#233044" }}
                  className="userName"
                >
                  Publishers and App Group
                </TableCell>
              </TableRow>
              {filterPublishers(publishers).length > 10 ? (
                <TableRow>
                  <TableCell>
                    <TextField
                      fullWidth
                      sx={{ minWidth: "200px" }}
                      label="Find publisher ..."
                      id="searchForPublisher"
                      onChange={findPublisher}
                    />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <Checkbox
                    checked={hideEmptyPublishers}
                    onChange={(e) => setHideEmptyPublishers(e.target.checked)}
                  />
                  <Typography fontSize={14}>Hide empty publishers</Typography>
                </TableCell>
              </TableRow>
              <TableRow
                className={
                  allAppsPublisher.id === selectedPublisher?.id
                    ? "userRow activeUserRow"
                    : "userRow"
                }
                sx={{
                  border: 1,
                }}
                onClick={() => {
                  handleClose();
                  setSelectedPublisher(allAppsPublisher);
                  myLocalStorage.setItem(
                    "lastSelectedPublisher",
                    allAppsPublisher,
                  );
                }}
              >
                <TableCell
                  scope="row"
                  className="userName"
                  component="td"
                  sx={{ marginBottom: "30px" }}
                >
                  All Apps
                </TableCell>
              </TableRow>
              {unsignedAppsPublisher ? (
                <TableRow
                  className={
                    unsignedAppsPublisher.id === selectedPublisher?.id
                      ? "userRow activeUserRow"
                      : "userRow"
                  }
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  onClick={() => {
                    handleClose();
                    setSelectedPublisher(unsignedAppsPublisher);
                    myLocalStorage.setItem(
                      "lastSelectedPublisher",
                      unsignedAppsPublisher,
                    );
                  }}
                >
                  <TableCell
                    scope="row"
                    className="userName"
                    component="td"
                    sx={{ marginBottom: "30px" }}
                  >
                    Unsigned Apps
                  </TableCell>
                </TableRow>
              ) : null}
              {filteredPublishers.map((publisher) => (
                <TableRow
                  key={publisher.id}
                  className={
                    publisher.id === selectedPublisher?.id
                      ? "userRow activeUserRow"
                      : "userRow"
                  }
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  onClick={() => {
                    handleClose();
                    setSelectedPublisher(publisher);
                    myLocalStorage.setItem("lastSelectedPublisher", publisher);
                  }}
                >
                  <StyledTooltip
                    arrow
                    title={
                      <Stack spacing={2}>
                        <Typography color="inherit">
                          {`Subject: ${publisher.subject}`}
                        </Typography>
                        <Typography color="inherit">
                          {`Thumbprint: ${publisher.thumbprint}`}
                        </Typography>
                      </Stack>
                    }
                    placement="left"
                  >
                    <TableCell
                      scope="row"
                      className="groupRowName userName"
                      component="td"
                    >
                      <>{publisher.friendlyName}</>
                    </TableCell>
                  </StyledTooltip>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box pl={5}>
          <p style={{ fontSize: "20px", fontWeight: "500" }}>
            There are no any publishers.
          </p>
        </Box>
      )}
    </>
  );
};

export default EndpointsPublishersTable;
