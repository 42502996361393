import { AppsTableHeader } from "./AppsTableHeader";
import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import axios from "axios";
import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import Paper from "@mui/material/Paper";

import RadioAuthFilter from "../../../components/RadioAuthFilter";
import StyledTooltip from "../../../components/StyledTooltip";
import ResourceRequestForm from "../../../components/ResourceRequestForm";
import { NGROK } from "../../../../APIs";
import useUserStore from "../../../../services/userStore";
import {
  adminTrustLevelStyles,
  checkIfUserHasAccess,
  getFilteredEndpointsResources,
  getInheritedEndpointResourceStatusValue,
  sortObjectsAlphabetically,
  userTrustLevelStyles,
} from "../../../../services/Helpers";
import Timer from "../../../components/Timer";
import { myLocalStorage } from "../../../../components/StorageHelper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useDebouncedValue from "../../../../hooks/useDebouncedValue";
import { BsFiletypeExe } from "react-icons/bs";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { debounce } from "lodash";

const EndpointsAppsTable = ({
  selectedUser,
  tenantName,
  selectedPublisher,
  showCount,
  setShowCount,
  showDetailBox,
  handleBoxOpen,
  handleClose,
}) => {
  const tableRef = useRef();
  const appRef = useRef();
  const queryClient = useQueryClient();

  const userData = useUserStore((state) => state.user);
  const activeComputer =
    useUserStore((state) => state.activeComputer) ||
    myLocalStorage.getItem("activeComputer");

  const [timedAppsWithoutAccess, setTimedAppsWithoutAccess] = useState([]);
  const [appsSearchValues, setAppsSearchValues] = useState("");
  const [filterAppsValue, setFilterAppsValue] = useState(
    userData.role !== "TENANT_USER" ? "ALL" : "DENIED",
  );
  const [apps, setApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState(null);

  const debouncedSearchTerm = useDebouncedValue(appsSearchValues, 1000);
  const [reputationEnabled, setReputationEnabled] = useState(false);

  const fetchApplications = async (selectedPublisher, userId) => {
    const appArr = [];

    const getUrl = (publisherId) =>
      `${NGROK}/api/${tenantName}/computer-users/${userId}/publishers/${publisherId}/apps`;

    const fetchData = async (url) => {
      const result = await axios.get(url);
      if (result.data) appArr.push(result.data.content);
    };

    if (selectedPublisher.friendlyName !== "Unsigned Apps") {
      await Promise.all(
        selectedPublisher?.ids?.map((publisherId) =>
          fetchData(getUrl(publisherId)),
        ),
      );
    } else {
      await fetchData(getUrl(selectedPublisher?.id));
    }

    return appArr.flat();
  };

  const changeAppTrustLevel = async ({ app, groupStatus }) => {
    const application = app?.computerUsersPublishersAppsMembership;
    const timeInSeconds = activeComputer.defaultTime * 60;

    try {
      return await axios.put(
        `${NGROK}/api/${tenantName}/computer-user/publishers/apps/timer`,
        {
          userId: application.computerUserId,
          publisherId: application.publisherId,
          appId: application.appId,
          email: userData.email,
          role: userData.role,
          groupStatus,
          selectedTime: timeInSeconds,
        },
      );
    } catch (error) {
      console.error(error);
      return app;
    }
  };

  const handleFilterResourceValue = (e) => {
    setFilterAppsValue(e.target.value);
  };

  const checkIfTimeShouldBeShown = (application) => {
    if (
      application.groupStatus === "DYNAMIC" ||
      (application.inheritedGroupStatus === "DYNAMIC" &&
        application.groupStatus === "INHERITED")
    ) {
      return true;
    } else return false;
  };

  const getAllApplications = async (selectedUserId, activeComputerId) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/computer-user/get-all-apps?userId=${selectedUserId}&computerId=${activeComputerId}`,
      );

      return response.data?.content;
    } catch (error) {
      console.error(error);
    }
  };

  const allAppsParameteresAreReady =
    !!selectedUser &&
    !!activeComputer &&
    !!(selectedPublisher.id === "allAppsPublisher");

  const endpointAppsParameteresAreReady =
    !!selectedUser &&
    !!selectedPublisher &&
    !!(selectedPublisher.id !== "allAppsPublisher");

  const { data: allApps, isLoading: allAppsAreLoading } = useQuery({
    queryKey: ["allApps", selectedUser.id, activeComputer.id],
    queryFn: () => getAllApplications(selectedUser.id, activeComputer.id),
    enabled: allAppsParameteresAreReady,
    refetchInterval: 5000,
  });

  const { data: publisherApps, isLoading: publisherAppsAreLoading } = useQuery({
    queryKey: ["publisherApps", selectedPublisher, selectedUser.id],
    queryFn: () => fetchApplications(selectedPublisher, selectedUser.id),
    enabled: endpointAppsParameteresAreReady,
    refetchInterval: 5000,
  });

  const updateTrustLevelMutation = useMutation({
    mutationFn: changeAppTrustLevel,
    onSuccess: async ({ data: newObj }) => {
      try {
        await queryClient.setQueryData(
          ["allApps", selectedUser.id, activeComputer.id],
          (oldQueryData) => {
            return oldQueryData?.map((item) =>
              item.appId === newObj.appId ? newObj : item,
            );
          },
        );
        await queryClient.setQueryData(
          ["publisherApps", selectedPublisher, selectedUser.id],
          (oldQueryData) => {
            return oldQueryData?.map((item) =>
              item.appId === newObj.appId ? newObj : item,
            );
          },
        );
      } catch (error) {
        console.error("Error setting query data:", error);
      }
    },
  });

  const handleRowSelect = (application) => {
    setSelectedApp((prevSelectedApp) =>
      prevSelectedApp &&
      prevSelectedApp?.computerUsersPublishersAppsMembership?.appId ===
        application?.computerUsersPublishersAppsMembership?.appId
        ? null
        : application,
    );
    handleBoxOpen();
  };

  const findApplication = (value) => {
    setAppsSearchValues(value);
  };

  useEffect(() => {
    if (allApps && selectedPublisher.id === "allAppsPublisher")
      setApps(allApps);
    else if (publisherApps) setApps(publisherApps);
    else setApps([]);
  }, [allApps, publisherApps, selectedPublisher.id]);

  const getUserApps = () => {
    const timedApps = sortObjectsAlphabetically(
      getFilteredEndpointsResources(debouncedSearchTerm, "TIMED", apps, true),
      "appName",
      "computerUsersPublishersAppsMembership",
    );

    const deniedApps = sortObjectsAlphabetically(
      getFilteredEndpointsResources(debouncedSearchTerm, "DENIED", apps, true),
      "appName",
      "computerUsersPublishersAppsMembership",
    );

    return [...timedApps, ...deniedApps];
  };

  const unicApps = (() => {
    if (!Array.isArray(apps)) return [];

    const seenPaths = new Set();
    return apps.reduce((accumulator, current) => {
      const path = current?.computerUsersPublishersAppsMembership?.path;
      if (path && !seenPaths.has(path)) {
        seenPaths.add(path);
        accumulator.push(current);
      }
      return accumulator;
    }, []);
  })();

  const filteredApplications = useMemo(() => {
    if (userData?.role !== "TENANT_USER") {
      return sortObjectsAlphabetically(
        getFilteredEndpointsResources(
          debouncedSearchTerm,
          filterAppsValue,
          unicApps,
          true,
        ),
        "appName",
        "computerUsersPublishersAppsMembership",
      );
    } else {
      return getUserApps();
    }
  }, [unicApps, debouncedSearchTerm, filterAppsValue, apps, userData?.role]);

  const handleScroll = () => {
    if (tableRef.current) {
      const table = tableRef.current;
      const rows = table.querySelectorAll("tr");
      if (rows.length >= showCount) {
        const fiftiethRow = rows[showCount - 1];
        const rect = fiftiethRow.getBoundingClientRect();
        const tableRect = table.getBoundingClientRect();
        if (
          rect.top >= tableRect.top &&
          rect.bottom <= tableRect.bottom &&
          filteredApplications?.length > showCount
        ) {
          setShowCount((prevCount) => prevCount + 50);
        }
      }
    }
  };

  const getFormattedSize = (sizeInBytes) => {
    if (sizeInBytes >= 1024 * 1024 * 1024) {
      return (sizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
    } else if (sizeInBytes >= 1024 * 1024) {
      return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
    } else if (sizeInBytes >= 1024) {
      return (sizeInBytes / 1024).toFixed(2) + " KB";
    } else {
      return sizeInBytes + " bytes";
    }
  };

  useLayoutEffect(() => {
    if (tableRef.current) {
      tableRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableRef.current) {
        tableRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [tableRef.current, apps, showCount]);

  const getReputationConfig = async () => {
    const response = await axios.get(
      `${NGROK}/api/${tenantName}/generalsettings`,
    );
    const data = response?.data;
    const configValue = data?.find(
      (config) => config.settingsName === "fileReputationCheckEnabled",
    );
    if (configValue) {
      setReputationEnabled(true);
    } else {
      setReputationEnabled(false);
    }
  };

  useEffect(() => {
    getReputationConfig();
  }, [apps]);

  const debouncedUpdateStatus = useMemo(
    () =>
      debounce((app, groupStatus) => {
        updateTrustLevelMutation.mutate({ app, groupStatus });
      }, 300),
    [],
  );

  const handleStatusChange = (application, newStatus) => {
    const resourceMapping = {
      ENABLED: "ALLOWED",
      DISABLED: "BLOCKED",
      DYNAMIC: "DYNAMIC",
      INHERITED: "INHERITED",
    };

    const updatedApplications = filteredApplications.map((app) =>
      app?.computerUsersPublishersAppsMembership?.appId ===
      application?.computerUsersPublishersAppsMembership?.appId
        ? {
            ...app,
            computerUsersPublishersAppsMembership: {
              ...app.computerUsersPublishersAppsMembership,
              groupStatus: newStatus,
              profileResource: resourceMapping[newStatus],
            },
          }
        : app,
    );

    setApps(updatedApplications);

    debouncedUpdateStatus(application, newStatus);
  };

  if (publisherAppsAreLoading || allAppsAreLoading) {
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box width="100%">
      {apps?.length ? (
        <Stack direction="column" spacing={2} width="100%">
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            sx={{
              padding: { xs: "10px", md: "15px" },
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
            }}
          >
            {userData?.role !== "TENANT_USER" ? (
              <RadioAuthFilter
                userRole={userData?.role}
                filterResourceValue={filterAppsValue}
                filterName="Filter by privilege"
                handleFilterResourceValue={handleFilterResourceValue}
              />
            ) : null}
            <TextField
              sx={{
                width: { xs: "100%", md: 250 },
                marginTop: { xs: 2, md: 0 },
              }}
              label="Search..."
              id="searchForApplication"
              value={appsSearchValues}
              onChange={(e) => findApplication(e.target.value)}
            />
          </Box>

          <Box sx={{ display: "flex", width: "100%", flexGrow: 1 }}>
            <Stack direction="column" spacing={2} width="100%">
              <Typography variant="h6" fontWeight={600} align="center">
                Total Count: {filteredApplications?.length || "0"}
              </Typography>
              <TableContainer
                component={Paper}
                ref={tableRef}
                sx={{ overflowX: "auto", flexGrow: 1 }}
              >
                <Table
                  sx={{
                    width: "100%",
                    "& td, & th": {
                      border: "1px solid #233044",
                      padding: { xs: "8px", md: "12px" },
                    },
                  }}
                  size="medium"
                >
                  <AppsTableHeader appRef={appRef} />
                  <TableBody>
                    {filteredApplications
                      .slice(0, showCount)
                      .map((application) => (
                        <TableRow
                          className="groupRow"
                          key={
                            application?.computerUsersPublishersAppsMembership
                              ?.appId
                          }
                          onClick={() => handleRowSelect(application)}
                          selected={
                            selectedApp?.computerUsersPublishersAppsMembership
                              ?.appId ===
                            application?.computerUsersPublishersAppsMembership
                              ?.appId
                          }
                          sx={{
                            "&:hover": {
                              background: "#f5f5f5",
                              cursor: "pointer",
                            },
                            "& td, & th": {
                              fontSize: { xs: "10px", md: "12px" },
                            },
                            "&.Mui-selected": {
                              background: "#233044 !important",
                              "& td, & th": {
                                color: "#ffffff !important",
                              },
                              "& svg": {
                                color: "#ffffff",
                              },
                            },
                          }}
                        >
                          <StyledTooltip
                            arrow
                            title={
                              <Typography color="inherit">
                                {`Path: ${application?.computerUsersPublishersAppsMembership?.path}`}
                              </Typography>
                            }
                            placement="left"
                          >
                            <TableCell className="userName">
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                justifyContent="flex-start"
                              >
                                {application?.applicationReputation ? (
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: "10px",
                                      height: "10px",
                                      borderRadius: "50%",
                                      backgroundColor:
                                        application?.applicationReputation
                                          ?.score === 0
                                          ? "green"
                                          : application?.applicationReputation
                                                ?.score > 0 &&
                                              application?.applicationReputation
                                                ?.score <= 50
                                            ? "yellow"
                                            : application?.applicationReputation
                                                  ?.score > 50 &&
                                                application
                                                  ?.applicationReputation
                                                  ?.score <= 75
                                              ? "orange"
                                              : application
                                                    ?.applicationReputation
                                                    ?.score > 75
                                                ? "red"
                                                : "green",
                                    }}
                                  />
                                ) : null}

                                <BsFiletypeExe style={{ fontSize: "1rem" }} />
                                <span
                                  style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    maxWidth: "150px",
                                  }}
                                >
                                  {
                                    application
                                      ?.computerUsersPublishersAppsMembership
                                      ?.appName
                                  }
                                </span>
                              </Stack>
                            </TableCell>
                          </StyledTooltip>
                          <TableCell className="privilegeLevelTableCell">
                            <Box
                              className={
                                userData.role === "TENANT_USER"
                                  ? userTrustLevelStyles(
                                      application?.computerUsersPublishersAppsMembership,
                                    )
                                  : adminTrustLevelStyles(
                                      application?.computerUsersPublishersAppsMembership,
                                    )
                              }
                            >
                              <Select
                                disabled={userData.role === "TENANT_USER"}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={
                                  application
                                    ?.computerUsersPublishersAppsMembership
                                    ?.groupStatus || ""
                                }
                                size="small"
                                onClick={(event) => event.stopPropagation()}
                                onChange={(event) => {
                                  handleStatusChange(
                                    application,
                                    event.target.value,
                                  );
                                }}
                                sx={{
                                  minWidth: "100px",
                                  color:
                                    selectedApp
                                      ?.computerUsersPublishersAppsMembership
                                      ?.appId ===
                                    application
                                      ?.computerUsersPublishersAppsMembership
                                      ?.appId
                                      ? "white"
                                      : "black",
                                  "& .MuiSvgIcon-root": {
                                    color:
                                      selectedApp
                                        ?.computerUsersPublishersAppsMembership
                                        ?.appId ===
                                      application
                                        ?.computerUsersPublishersAppsMembership
                                        ?.appId
                                        ? "white"
                                        : "black",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor:
                                      selectedApp
                                        ?.computerUsersPublishersAppsMembership
                                        ?.appId ===
                                      application
                                        ?.computerUsersPublishersAppsMembership
                                        ?.appId
                                        ? "white"
                                        : "black",
                                  },
                                }}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      bgcolor: "#233044",
                                      "& .MuiMenuItem-root": {
                                        color: "white !important",
                                      },
                                      "& .MuiMenuItem-root.Mui-selected": {
                                        bgcolor: "#233044 !important",
                                        color: "white !important",
                                      },
                                    },
                                  },
                                }}
                              >
                                <MenuItem value="ENABLED">ALLOWED</MenuItem>
                                <MenuItem value="DISABLED">DENIED</MenuItem>
                                <MenuItem value="DYNAMIC">TIMED</MenuItem>
                                <MenuItem value="INHERITED">
                                  INHERITED{" "}
                                  {getInheritedEndpointResourceStatusValue(
                                    application?.computerUsersPublishersAppsMembership,
                                  )}
                                </MenuItem>
                              </Select>
                              {checkIfTimeShouldBeShown(
                                application?.computerUsersPublishersAppsMembership,
                              ) ? (
                                <Timer
                                  setTimedResourcesWithoutAccess={
                                    setTimedAppsWithoutAccess
                                  }
                                  resourceId={
                                    application
                                      ?.computerUsersPublishersAppsMembership
                                      .appId
                                  }
                                  seconds={
                                    application
                                      ?.computerUsersPublishersAppsMembership
                                      .remainingTime
                                  }
                                />
                              ) : null}
                              {!checkIfUserHasAccess(
                                application?.computerUsersPublishersAppsMembership,
                                userData,
                                timedAppsWithoutAccess,
                                application
                                  ?.computerUsersPublishersAppsMembership.appId,
                              ) ? (
                                <ResourceRequestForm
                                  publisherId={
                                    application
                                      ?.computerUsersPublishersAppsMembership
                                      .publisherId
                                  }
                                  tenantName={tenantName}
                                  resourceName={
                                    application
                                      ?.computerUsersPublishersAppsMembership
                                      .path
                                  }
                                  resourcePath={
                                    application
                                      ?.computerUsersPublishersAppsMembership
                                      .path
                                  }
                                  selectedUser={selectedUser}
                                  resourceId={
                                    application
                                      ?.computerUsersPublishersAppsMembership
                                      .appId
                                  }
                                  resourceType={"APP"}
                                  computer={activeComputer}
                                  isFromEndPointApp={true}
                                />
                              ) : null}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    {filteredApplications?.length > showCount && (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            {selectedApp &&
              showDetailBox &&
              (() => {
                const sizeInBytes =
                  selectedApp?.computerUsersPublishersAppsMembership?.size || 0;
                const formattedSize = getFormattedSize(sizeInBytes);

                return (
                  <Box
                    sx={{
                      mt: 6.5,
                      ml: 2,
                      width: "350px",
                      display: "flex",
                      flexDirection: "column",
                      border: "1px solid",
                      flexShrink: 0,
                      bgcolor: "#f9f9f9",
                      borderRadius: "5px",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                      position: "relative",
                    }}
                  >
                    <IconButton
                      onClick={handleClose}
                      sx={{
                        position: "absolute",
                        top: 3,
                        right: 8,
                        color: "#fff",
                        backgroundColor: "#233044",
                        "&:hover": {
                          backgroundColor: "#1c2735",
                        },
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        background: "#233044",
                        color: "#fff",
                        padding: "10px",
                        borderRadius: "5px 5px 0 0",
                        fontSize: "18px",
                        textAlign: "center",
                      }}
                    >
                      Details
                    </Typography>

                    <List dense sx={{ padding: 2 }}>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Box
                              display="flex"
                              flexDirection="row"
                              flexWrap="wrap"
                            >
                              <Typography sx={{ fontSize: 16, marginRight: 1 }}>
                                <strong>Name: </strong>
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 16,
                                  overflowWrap: "break-word",
                                  wordWrap: "break-word",
                                  whiteSpace: "normal",
                                  flex: 1,
                                  minWidth: 0,
                                }}
                              >
                                {
                                  selectedApp
                                    ?.computerUsersPublishersAppsMembership
                                    ?.appName
                                }
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={
                            <Box
                              display="flex"
                              flexDirection="row"
                              flexWrap="wrap"
                            >
                              <Typography sx={{ fontSize: 16, marginRight: 1 }}>
                                <strong>Path: </strong>
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 16,
                                  overflowWrap: "break-word",
                                  wordWrap: "break-word",
                                  whiteSpace: "normal",
                                  flex: 1,
                                  minWidth: 0,
                                }}
                              >
                                {
                                  selectedApp
                                    ?.computerUsersPublishersAppsMembership
                                    ?.path
                                }
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={
                            <Box
                              display="flex"
                              flexDirection="row"
                              flexWrap="wrap"
                            >
                              <Typography sx={{ fontSize: 16, marginRight: 1 }}>
                                <strong>SHA-256: </strong>
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 16,
                                  overflowWrap: "break-word",
                                  wordWrap: "break-word",
                                  whiteSpace: "normal",
                                  flex: 1,
                                  minWidth: 0,
                                }}
                              >
                                {
                                  selectedApp
                                    ?.computerUsersPublishersAppsMembership
                                    ?.fingerprintSha256
                                }
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={
                            <Box
                              display="flex"
                              flexDirection="row"
                              flexWrap="wrap"
                            >
                              <Typography sx={{ fontSize: 16, marginRight: 1 }}>
                                <strong>Size: </strong>
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 16,
                                  overflowWrap: "break-word",
                                  wordWrap: "break-word",
                                  whiteSpace: "normal",
                                  flex: 1,
                                  minWidth: 0,
                                }}
                              >
                                {formattedSize}
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>

                      {selectedApp?.applicationReputation?.score > 0 && (
                        <>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Box display="flex" flexDirection="column">
                                  <Typography sx={{ fontSize: 16 }}>
                                    <strong>Verdict:</strong>{" "}
                                    {
                                      selectedApp?.applicationReputation
                                        ?.verdict
                                    }
                                  </Typography>
                                </Box>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Box display="flex" flexDirection="column">
                                  <Typography sx={{ fontSize: 16 }}>
                                    <strong>Status:</strong>{" "}
                                    {
                                      selectedApp?.applicationReputation
                                        ?.rawData?.status
                                    }
                                  </Typography>
                                </Box>
                              }
                            />
                          </ListItem>

                          {selectedApp?.applicationReputation?.catagory
                            ?.length > 0 && (
                            <ListItem>
                              <ListItemText
                                primary={
                                  <Typography sx={{ fontSize: 16 }}>
                                    <strong>Category:</strong>{" "}
                                    {selectedApp.applicationReputation.catagory.join(
                                      ", ",
                                    )}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          )}

                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>Platform:</strong>{" "}
                                  {selectedApp?.applicationReputation?.rawData
                                    ?.classification?.platform || "N/A"}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>Type:</strong>{" "}
                                  {selectedApp?.applicationReputation?.rawData
                                    ?.classification?.type || "N/A"}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>FamilyName:</strong>{" "}
                                  {selectedApp?.applicationReputation?.rawData
                                    ?.classification?.familyName || "N/A"}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>ThreatName:</strong>{" "}
                                  {selectedApp?.applicationReputation?.rawData
                                    ?.threatName || "N/A"}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>Reason:</strong>{" "}
                                  {selectedApp?.applicationReputation?.rawData
                                    ?.reason || "N/A"}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>Scanner Percent:</strong>{" "}
                                  {selectedApp?.applicationReputation?.rawData
                                    ?.scannerPercent || "N/A"}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>First seen:</strong>{" "}
                                  {selectedApp?.applicationReputation?.rawData
                                    ?.firstSeen
                                    ? moment(
                                        selectedApp?.applicationReputation
                                          ?.rawData?.firstSeen,
                                      ).format("YYYY-MM-DD HH:mm")
                                    : "N/A"}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>Last seen:</strong>{" "}
                                  {selectedApp?.applicationReputation?.rawData
                                    ?.lastSeen
                                    ? moment(
                                        selectedApp?.applicationReputation
                                          ?.rawData?.lastSeen,
                                      ).format("YYYY-MM-DD HH:mm")
                                    : "N/A"}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </>
                      )}
                    </List>
                  </Box>
                );
              })()}
          </Box>
        </Stack>
      ) : (
        <Typography variant="h6">There are no applications.</Typography>
      )}
    </Box>
  );
};

export default EndpointsAppsTable;
