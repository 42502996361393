import React from "react";

import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useLocation } from "react-router-dom";
import useUserStore from "../services/userStore";
import { myLocalStorage } from "./StorageHelper";

const BreadcrumbsNavProfiles = () => {
  const location = useLocation();

  const storageProfile = myLocalStorage.getItem("selectedProfile");
  const storeProfile = useUserStore((state) => state.selectedProfile);
  const selectedProfile = storeProfile || storageProfile;

  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{ fontSize: "22px" }}
    >
      <Link style={{ color: "black" }} to={"/policyProfiles"}>
        Tenants
      </Link>
      {latestTenant && (
        <Typography fontSize={22} color="text.primary">
          {latestTenant}
        </Typography>
      )}
      <Link style={{ color: "black" }} to={"/policyProfiles"}>
        Profiles
      </Link>
      {location.pathname === "/policyProfiles/resources" && selectedProfile ? (
        <Typography fontSize={22} color="text.primary">
          {selectedProfile.name}
        </Typography>
      ) : null}
      {location.pathname === "/policyProfiles/categories" && selectedProfile ? (
        <Typography fontSize={22} color="text.primary">
          {selectedProfile.name}
        </Typography>
      ) : null}
      {location.pathname === "/policyProfiles/categories" && selectedProfile ? (
        <Typography fontSize={22} color="text.primary">
          Categories
        </Typography>
      ) : null}
      {location.pathname === "/policyProfiles/profileSettings" &&
      selectedProfile ? (
        <Typography fontSize={22} color="text.primary">
          {selectedProfile.name}
        </Typography>
      ) : null}
      {location.pathname === "/policyProfiles/profileSettings" &&
      selectedProfile ? (
        <Typography fontSize={22} color="text.primary">
          Profile Settings
        </Typography>
      ) : null}
    </Breadcrumbs>
  );
};

export default BreadcrumbsNavProfiles;
