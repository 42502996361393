import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import useUserStore from "../../../services/userStore";

const SelectOS = ({ selectedOS, setSelectedOS }) => {
  const userData = useUserStore((state) => state.user);
  let userRole = !userData.userRole ? "" : userData.userRole;
  const osList = ["WINDOWS", "LINUX", "MACOS"];
  const filteredOSList = osList.filter((os) => {
    if (userRole === "epam-admin") {
      return os !== "LINUX";
    } else if (userRole === "server-pam-admin") {
      return os !== "MACOS";
    }
    return true;
  });

  return (
    <>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={selectedOS}
          onChange={(e) => setSelectedOS(e.target.value)}
          name="radio-buttons-group"
          row
        >
          {filteredOSList.map((os) => (
            <FormControlLabel
              key={os}
              value={os}
              control={<Radio />}
              label={os}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default SelectOS;
