import { useState, useEffect } from "react";

const useGeneralSearch = (
  initialData,
  searchFields = null,
  forbidToSearchFields = null,
) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(initialData);

  useEffect(() => {
    const filteredResults = initialData?.filter((item) => {
      const hasSearchTerm = (value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase());

      return Object.entries(item).some(([key, value]) => {
        if (!value) return false;

        if (searchFields) {
          if (searchFields.includes(key)) {
            if (Array.isArray(value)) {
              return value.some((arrayItem) => {
                if (typeof arrayItem === "object" && arrayItem.categoryName) {
                  return hasSearchTerm(arrayItem.categoryName);
                }
                return hasSearchTerm(arrayItem);
              });
            }
            return hasSearchTerm(value);
          }
          return false;
        }

        if (forbidToSearchFields) {
          if (forbidToSearchFields.includes(key)) return false;
          return hasSearchTerm(value);
        }

        return hasSearchTerm(value);
      });
    });

    setFilteredData(filteredResults);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, initialData]);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };
  return { searchTerm, filteredData, handleSearch };
};

export default useGeneralSearch;
